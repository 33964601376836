import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import AOS from "aos";

import "./About.scss";
import subteams from "./../../components/Subteams";
import hilift from "./../../img/about/hi_lift.jpg";
import advanced from "./../../img/about/advanced.jpg";
import autonomous from "./../../img/about/autonomous.jpg";

export default function About() {
  useEffect(() => {
    AOS.init({
      easing: "ease",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>About | M-Fly</title>
      </Helmet>
      <div className="About">
        <div className="bgimg bgimg-mission"></div>
        <div className="white-div">
          <h3 className="text-center">The Mission</h3>
          <p className="description-center">
            M-Fly’s mission is to provide a multi-disciplinary learning
            experience for students while establishing a tradition of
            competitiveness and success in the SAE Aero Design and SUAS
            competitions. Our mission is to provide valuable hands-on learning
            experiences for Michigan students outside the classroom through
            engineering practices, teamwork, and professional development
            opportunities. We also seek to create a safe and inclusive learning
            environment for students to develop their technical, professional,
            and interpersonal skills through engagement with complex, real-world
            engineering challenges.
          </p>
        </div>
        <div className="blue-div">
          <h3>Hi-Lift</h3>
          <p className="clearfix ox">
            <img
              src={hilift}
              alt="Hi-Lift"
              className="img float-right"
              data-aos="fade-left"
              data-aos-easing="ease"
            />
            The Hi-Lift aircraft competes in a competition that prioritizes maximizing payload.
            The aircraft is led by a chief engineer, who is in charge of four technical
            leads and their sub-team members. The team designs the aircraft in the Fall
            semester and manufactures the aircraft in the Winter semester before attending
            competition in the Spring. This past year's aircraft, the M-16, maximizes
            wingspan and carries steel plates. The team is happy to report that they
            finished 2nd place in design this year!
          </p>
        </div>
        <div className="white-div">
          <h3>Advanced Class</h3>
          <p className="clearfix">
            <img
              src={advanced}
              alt="Advanced Class"
              className="img float-left"
              data-aos="fade-right"
              data-aos-easing="ease"
            />
            The Advanced Class aircraft competes in a systems competition. The
            aircraft is designed by a team of six lead engineers and their
            sub-teams, coordinated by one chief engineer. The team selects a
            design configuration during the Fall semester to fully manufacture
            and test during the Winter semester. The current competition mimics
            a multi-step firefighting mission, where a mother plane oversees
            water payload and the deployment of a secondary aircraft, which once
            deployed, will autonomously deliver parts necessary to assemble a
            miniature ground transport vehicle to a designated target. Once this
            is completed, there will be a demonstration for the miniature
            vehicle to carry water through an obstacle course. The Advanced Class team
            is proud to report a 3rd place overall finish (2nd in US), with a 2nd place
            report finish (1st in the US), and 3rd place oral presentation in the 2024
            SAE Aero Competition.

          </p>
        </div>
        <div className="blue-div">
          <h3>Autonomous</h3>
          <p className="clearfix ox">
            <img
              src={autonomous}
              alt="Autonomous"
              className="img float-right"
              data-aos="fade-left"
              data-aos-easing="ease"
            />
            For the SUAS competition, we design, build, and fly a fully unmanned 
            aircraft (UAV) that carries four payloads. The plane is equipped with 
            onboard sensors and computers that help it autonomously navigate waypoints, 
            detect & classify targets, and drop payloads. Engineers from our propulsion, 
            aerodynamics, and structures sub-teams help design the UAV, while those from our 
            software and hardware teams work on our onboard avionics systems. It's a 
            complicated machine that requires flawless teamwork among all members. We're 
            excited to debut our newest plane, MAT-6, at the competition this year. 
            After our strong qualification last year, we're excited to see what we 
            can get done on the field.
          </p>
        </div>
        <div className="bgimg bgimg-subteams"></div>
        <div className="white-div">
          <h3 className="text-center">Subteams</h3>
          <p className="subteam-description">
            Each of our aircraft programs has a dedicated set of subteams which
            focus on various aspects of aircraft design, manufacturing, and
            testing. Some subteams play an integral role in all three aircraft
            programs, where others serve a mission-specific purpose for one or
            two of the programs.
          </p>
          <ul className="card-container">
            {subteams.map(({ name, image, description }) => (
              <li className="card-item" key={name}>
                <div className="card">
                  <img src={image} alt={name} className="card-image" />
                  <h4 className="card-title">{name}</h4>
                  <p className="card-text">{description}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
