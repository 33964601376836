import React from "react";
import { Switch, Route } from "react-router-dom";

import "./App.scss";
import "./css/parallax.css";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Members from "./pages/Members/Members";
import Aircraft from "./pages/Aircraft/Aircraft";
import Sponsors from "./pages/Sponsors/Sponsors";
import Contact from "./pages/Contact/Contact";
import FRR from "./pages/FRR/FRR";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <div className="App">
      <Navbar />
      <div className="Page">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/members" component={Members} />
          <Route exact path="/aircraft" component={Aircraft} />
          <Route exact path="/sponsors" component={Sponsors} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/frr" component={FRR} />
          <Route path="*" component={NotFound} />
        </Switch>
      </div>
    </div>
  );
}

export default App;
