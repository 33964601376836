import React from "react";
import { Helmet } from "react-helmet-async";

import "./Contact.scss";

export default function Contact() {
  return (
    <>
      <Helmet>
        <title>Contact | M-Fly</title>
      </Helmet>
      <div className="Contact">
        <h2 className="text-center">
          Email us at <a href="mailto:mfly@umich.edu">mfly@umich.edu</a> and
          subscribe to{" "}
          <a
            href="https://forms.gle/HvMKgtZzHfmvTUNA6"
            target="_blank"
            rel="noopener noreferrer"
          >
            our newsletter
          </a>
          !
        </h2>
        <div className="white-div">
          <div className="gcal">
            <iframe
              src="https://calendar.google.com/calendar/embed?src=umich.edu_r7lpifab9c81kiojs6dg2vje2g%40group.calendar.google.com&ctz=America%2FDetroit&mode=WEEK"
              width="100%"
              height="600"
              frameborder="0"
              scrolling="no"
              title="Google Calendar"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}
