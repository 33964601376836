import React from "react";

import aerodynamics from "../img/about/aerodynamics.jpg";
import avionics from "../img/about/avionics.jpg";
import composites from "../img/about/composites.jpg";
import hardware from "../img/about/hardware.jpg";
import manufacturing from "../img/about/manufacturing.jpg";
import propulsion from "../img/about/propulsion.jpg";
import software from "../img/about/software.jpg";
import structures from "../img/about/structures.jpg";

const subteams = [
  {
    name: "Aerodynamics",
    image: aerodynamics,
    description: (
      <>
        The Aerodynamics subteam is the fundamental design area for M-Fly. The
        subteam works to design an aircraft that can carry the appropriate
        payload, take off within runway constraints, and complete the mission
        successfully.
      </>
    ),
  },
  {
    name: "Avionics",
    image: avionics,
    description: (
      <>
        The Avionics subteam works on the Advanced Class aircraft. The team is
        responsible for both hardware and software of our deployable autonomous
        gliders, our payload drop system actuation, and drop calculations to
        make sure we hit our targets.
      </>
    ),
  },
  {
    name: "Hardware",
    image: hardware,
    description: (
      <>
        The Hardware team works on the Autonomous aircraft's electronics. They
        are responsible for the hardware that handles autonomy, computer vision,
        and other aspects of the aircraft's control system.
      </>
    ),
  },
  {
    name: "Manufacturing",
    image: manufacturing,
    description: (
      <>
        The Manufacturing subteam takes the computerized aircraft design and
        builds it from the ground up. They also work with the Aerodynamics and
        Structures subteams to ensure the design is manufacturable and to
        prototype components for validation purposes. In addition to wood, we
        work with carbon fiber and fiberglass and design and manufacture many of
        our own molds.
      </>
    ),
  },
  {
    name: "Propulsion",
    image: propulsion,
    description: (
      <>
        The Propulsion subteam is responsible for selecting and testing the
        optimal motor-propeller combination which will provide the aircraft with
        enough thrust to complete its mission while still remaining within the
        competition power constraints.
      </>
    ),
  },
  {
    name: "Software",
    image: software,
    description: (
      <>
        The Software subteam works on the Autonomous aircraft and is reponsible
        for all software on the aircraft, including the flight systems and the
        computer vision.
      </>
    ),
  },
  {
    name: "Structures",
    image: structures,
    description: (
      <>
        The Structures subteam is responsible for the mechanical design of the
        aircraft and ensures the system can handle all flight loads predicted by
        the Aerodynamics subteam. The subteam also has a major role in systems
        integration, working to create appropriate space and mounting hardware
        for all other physical systems.
      </>
    ),
  },
];

export default subteams;
