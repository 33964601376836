import React from "react";
import { Helmet } from "react-helmet-async";

import "./Sponsors.scss";
import sponsors from "../../components/SponsorsList";

export default function Sponsors() {
  return (
    <>
      <Helmet>
        <title>Sponsors | M-Fly</title>
      </Helmet>
      <div className="Sponsors">
        <div className="white-div">
          <h1 className="text-center">Sponsorships</h1>
          <p className="description-center">
            M-Fly relies on donations and corporate sponsorships to fund all of
            our operations throughout the school year. If you are interested in
            donating to our team, button below, and you'll be redirected to our 
            Giving Blueday
            profile. Your generosity will assist us in expenses
            related to purchasing materials for aircraft fabrication, designing
            and testing our prototypes, funding competition registration and
            travel, and hosting community outreach events.
          </p>
          <p className="text-center">
            <a
              href="https://leadersandbest.umich.edu/find/#!/give/basket/fund/937675"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="donate-btn">DONATE!</button>
            </a>
          </p>
        </div>
        {sponsors.map(({ tier, list }) => (
          <div className="white-div">
            <h1 className="blue-div">{tier}</h1>
            <div className="sponsors-container">
              {list.map(({ name, image, url }) => (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <img src={image} alt={name} className={"tier-" + tier} />
                </a>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
