import React from "react";
import "./FRR.scss";
import { Helmet } from "react-helmet-async";

export default function FRR(){
    return (
        <>
            <Helmet>
                <title>FRR | M-Fly</title>
            </Helmet>
            <div className="FRR">
                <h1 className="text-center">2024 SUAS FRR</h1>
                <iframe width="660" height="500" src="https://www.youtube.com/embed/L67OMYoMGrk" title="University of Michigan - Ann Arbor M-Fly Aero Design SUAS FRR 2024" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </>
    )
}