import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import AOS from "aos";

import "./Home.scss";
import logo from "./../../img/logos/mfly_white.png";
import hilift from "./../../img/home/hi_lift.jpg";
import advanced from "./../../img/home/advanced.jpg";
import auton from "./../../img/home/auton.jpg";

export default function Home() {
  useEffect(() => {
    AOS.init({
      easing: "ease",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>M-Fly</title>
      </Helmet>
      <div className="Home">
        <div className="bgimg bgimg-team">
          <div className="caption">
            <img
              src={logo}
              alt="M-Fly"
              className="logo"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-duration="2000"
            />
            <h1
              data-aos="fade-up"
              data-aos-delay="800"
              data-aos-anchor=".Home .logo"
              data-aos-duration="2000"
            >
              Welcome aboard.
            </h1>
          </div>
        </div>

        <div className="white-div">
          <h3 className="text-center">Who We Are</h3>
          <p>
            M-Fly is an multidisciplinary aircraft design team at U-M whose
            mission is to design, build, test, and compete three aircraft for
            the SAE Aero Design and SUAS competitions. The entire process is
            student-driven, making it an incredibly valuable learning experience
            for all students involved. Each competition involves designing an
            aircraft to carry out a particular mission. Our Hi-Lift plane
            focuses on high-lift and payload delivery applications, our Advanced
            Class plane focuses on designing a mother plane that delivers a
            water payload and deploys a secondary aircraft to simulate a
            firefighting mission, and our Autonomous plane focuses on building
            an unmanned aerial system capable of autonomous flight and
            navigation, and the execution of specific flight systems and
            computer vision tasks.
          </p>
          <p>
            Our team is composed of members from many different majors and
            backgrounds and is by no means geared specifically towards just
            aerospace majors. As a team, our vision is to promote aircraft
            design and education, and we focus on helping our team members and
            students grow not only as engineers but also as citizens. Within
            M-Fly, students are able to apply what they have learned in their
            classes in the process of aircraft design, such as CAD, CFD, and FEA
            for simulations, see concepts they’ve learned in classes such as
            fluid dynamics and structures be applied, as well as develop
            components using mechanical design like landing gear. Team members
            also have the opportunity to learn concepts and skills that might
            not be specifically taught in their classes, such as composite
            construction and manufacturing processes. This combination of
            classroom teaching and extending that knowledge through designing an
            aircraft provides a more complete educational experience for
            students.
          </p>
        </div>

        <div className="bgimg bgimg-hilift">
          <div className="caption">
            <span className="border" data-aos="fade-up">
              HI-LIFT
            </span>
          </div>
        </div>

        <div className="blue-div">
          <p className="clearfix ox">
            <img
              src={hilift}
              alt="Hi-Lift"
              className="img float-right"
              data-aos="fade-left"
              data-aos-easing="ease"
            />
            The Hi-Lift program is the oldest program at M-Fly. For the past 16 years, 
            the program has competed in the SAE Aero Design competition, where it specifically 
            partakes in the Regular Class competition. The Hi-Lift plane is remote-controlled 
            and manufactured from a restrictive pool of materials. This year, the objective of 
            the SAE Aero Design Regular Class competition is to design an aircraft that can 
            lift as much weight as required while maximizing wingspan. Payload consisting 
            of steel plates must be carried on each flight. This past year, the M-16 “Zelus” 
            finished second overall (first in the nation) in design report, and 13th 
            overall out of 32 teams.

          </p>
        </div>

        <div className="bgimg bgimg-advanced">
          <div className="caption">
            <span className="border" data-aos="fade-up">
              ADVANCED CLASS
            </span>
          </div>
        </div>

        <div className="white-div">
          <p className="clearfix">
            <img
              src={advanced}
              alt="Advanced Class"
              className="img float-left"
              data-aos="fade-right"
            />
            The Advanced Class program just completed its 9th year on M-Fly and competing
            in the SAE Aero Design Competition. It is a systems-engineering based competition
            where the aircraft must complete a firefighting mission with the delivery of static
            water payload, the deployment of a parasite secondary autonomous aircraft towards
            ground targets, and the delivery assembly of a ground vehicle. This past years aircraft
            was the MX-9 "Phoenix" which, along with the secondary PADA, was awarded the top
            design in the nation, and third overall.
          </p>
        </div>

        <div className="bgimg bgimg-autonomous">
          <div className="caption">
            <span className="border" data-aos="fade-up">
              AUTONOMOUS
            </span>
          </div>
        </div>

        <div className="blue-div">
          <p className="clearfix ox">
            <img
              src={auton}
              alt="Autonomous"
              className="img float-right"
              data-aos="fade-left"
            />
            The Autonomous team is currently in its 8th year and participates in 
            the <a href="https://suas-competition.org/">SUAS</a> competition. This 
            aircraft must autonomously complete a mission of 
            flight systems and computer vision challenges, including navigation, obstacle 
            avoidance, object detection and classification, and a payload drop. In addition, 
            the plane should optimize to minimize weight and size for ease of portability. The 
            Autonomous team goes through an aircraft design cycle and also provides 
            electrical engineering, computer science, and robotics challenges. We are 
            looking forward to developing MAT-6 for this year's competition.
          </p>
        </div>

        <div className="bgimg bgimg-all">
          <div className="caption">
            <span className="border">
              <a
                href="mailto:mfly@umich.edu"
                style={{
                  textDecoration: "none",
                  color: "var(--blue)",
                  textTransform: "uppercase",
                }}
              >
                mfly@umich.edu
              </a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
